<template>
  <div id="main-wrapper" class="inner-pages why-page server-page">
    <div class="section banner-holder">
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>Our Servers</h2>
        <p>Robust Set up for a Flawless Experience</p>
      </div>
    </div>
    <div class="section why-bel equi-sec">
      <div class="wrapper">
        <div class="wow fadeIn">
          <div class="title-holder t-center">
            <h3>Equinix Data Centers</h3>
          </div>
          <h6>
            In our endeavour to provide a world class trading experience to our
            clients, we rely on nothing less than the best technology and
            infrastructure in the world. We aim to provide the most reliable and
            fastest connectivity when it comes to trade execution and therefore,
            we use the world’s most advanced global network and server clusters.
          </h6>
          <br />
          <div class="title-holder wow fadeIn t-center">
            <h3>What are the advantages of Equinix?</h3>
          </div>
          <div class="equinix-listing t-center">
            <div class="equinix-holder wow fadeIn">
              <div class="img-holder">
                <img src="assets/images/eq1.webp" alt="AAAFx" title="AAAFx" width="126" height="126" class="fluid-img" />
              </div>
              <h3>Fastest Execution</h3>
              <p>
                The Data Center is collocated with our major LPs, thereby
                providing fastest connectivity possible.
              </p>
            </div>
            <div class="equinix-holder wow fadeIn" data-wow-delay="0.3s">
              <div class="img-holder">
                <img src="assets/images/eq2.webp" alt="AAAFx" title="AAAFx" width="126" height="126" class="fluid-img" />
              </div>
              <h3>Low latency connection</h3>
              <p>
                The fast connection between our trade servers and LPs through dedicated channel ensures least latency possible.
              </p>
            </div>
            <div class="equinix-holder wow fadeIn" data-wow-delay="0.6s">
              <div class="img-holder">
                <img src="assets/images/eq3.webp" alt="AAAFx" title="AAAFx" width="126" height="126" class="fluid-img" />
              </div>
              <h3>Uninterrupted trading</h3>
              <p>
                Instant and seamless connectivity, maximum up time, making your trading experience flawless.
              </p>
            </div>
            <div class="equinix-holder wow fadeIn" data-wow-delay="0.9s">
              <div class="img-holder">
                <img src="assets/images/eq4.webp" alt="AAAFx" title="AAAFx" width="126" height="126" class="fluid-img" />
              </div>
              <h3>Low slippage</h3>
              <p>
                Ultra-fast connection means you get the price you want, with minimal chances of slippage
              </p>
            </div>
            <div class="equinix-holder wow fadeIn" data-wow-delay="1.2s">
              <div class="img-holder">
                <img src="assets/images/eq5.webp" alt="AAAFx" title="AAAFx" width="126" height="126" class="fluid-img" />
              </div>
              <h3>Easy connectivity</h3>
              <p>
                You do not have to struggle with connectivity when you are connecting with a dense network that works on cutting edge technology
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section equi-what">
      <div class="wrapper">
        <div class="mockup-holder wow fadeIn">
          <img src="assets/images/mockup-holder.webp" alt="AAAFx" title="AAAFx" width="864" height="511" class="fluid-img" />
          <div class="video-holder">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/8NvjI0lCUqE?autoplay=1&rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="section equi-accord">
      <div class="wrapper">
        <div class="accordion-holder wow fadeIn">
          <h3 v-on:click="Showing1 ^= true" :class="[{active : Showing1}]">What is Equinix?</h3>
          <div class="content" v-show="Showing1">
            <div class="text-holder">
              <p>
                Equinix, Inc. (NASDAQ: EQIX) is an American multinational
                company headquartered in Redwood City, California, that
                specializes in Internet connectivity and data center management.
                <br/><br/>
                The company is a leader in global co-location with 229 data
                centers in 27 countries on five continents. Additionally, they
                have won various awards for reliability and sustainability.
                Their Data Centers are considered to be the gold standard, and
                as the most reliable and secure digital infrastructure.
              </p>
            </div>
            <div class="img-holder img-holder-spacing">
              <img src="assets/images/equi1.webp" alt="AAAFx" title="AAAFx" width="338" height="216" class="fluid-img" />
            </div>
          </div>
          <h3 v-on:click="Showing2 ^= true"  :class="[{active : Showing2}]">What is the LD4 Data Center?</h3>
          <div class="content" v-show="Showing2">
            <p>
              Equinix LD4 data center where most of the AAAFx servers are housed
              is the Forex Industry’s most prime location for server placement,
              as it enables a direct straight through connectivity with all
              major Global liquidity providers. It is over 14,000 sq. ft in size
              and holds various global certifications, like SOC 1 Type 1, SOC 2
              Type 2, ISO 27001, PCI DSS, OHSAS 18001, ISO 9001:2015, ISO 22301,
              ISO 14001:1015 and ISO 50001. <br />The accelerated connection to
              our liquidity providers through the dense network enables
              ultra-fast trade execution with least latency.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        Showing1:false,
        Showing2:false,
      }
    }
  }

</script>